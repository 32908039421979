<template>
  <v-card v-if="apiLoaded">
    <v-card-title>
      <span class="text-h5">{{editAppIndex == null ? 'Добавление заявки' : 'Редактирование заявки'}}</span>
      <a class="close" @click="$emit('close')">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
              fill="#0033A0"/>
        </svg>
      </a>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-form ref="form">
          <div class="">

            <v-row>
              <v-col cols="12">
                <v-checkbox label="Внешняя организация" v-model="isExternalOrg"></v-checkbox>
              </v-col>
            </v-row>

            <v-row v-if="!isExternalOrg">
              <v-col cols="12">
                <v-select
                    label="Организация"
                    :items="ourOrgs"
                    item-text="shortName"
                    item-value="id"
                    return-object
                    v-model="application.ourOrg"
                    outlined dense
                ></v-select>
              </v-col>
            </v-row>

            <v-row v-if="isExternalOrg">
              <v-col cols="12">
                <v-text-field label="Название внешней организации"
                              v-model="application.differentOrgName"
                              outlined dense></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-select
                    label="Ответственный"
                    :items="experts"
                    item-text="fullName"
                    item-value="id"
                    return-object
                    v-model="application.offerExpert"
                    outlined dense
                    :rules="[
                    (v=>(v != null) || 'Поле не должно быть пустым'),
                  ]"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field
                    outlined dense
                    label="Сумма заявки, ₽"
                    :hint="'Снижение: ' + discount + '%'"
                    v-model="application.appPrice"
                    :rules="[v=>(v==null||v.length===0||!!normalizeNumber(v, 0, 1e13, 2)||'Введите корректную сумму')]"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-checkbox label="Приоритетная заявка" v-model="application.highPriority"></v-checkbox>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-select
                    label="Оценка риска"
                    :items="riskAssessmentDict"
                    item-text="title"
                    item-value="code"
                    v-model="application.riskAssessment"
                    outlined dense
                    :rules="[
                    (v=>(v != null) || 'Поле не должно быть пустым'),
                  ]"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-select
                    label="Куратор"
                    :items="partners"
                    item-text="fullName"
                    item-value="id"
                    return-object
                    v-model="application.curator"
                    v-if="application.offerExpert && !application.offerExpert.isRolePartner"
                    outlined dense
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-checkbox label="Получено согласие партнеров" v-model="application.partnersApproved"></v-checkbox>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
              >
                <v-select v-if="!application.partnersApproved"
                          label="Партнеры, разделяющие риск"
                          :items="partners"
                          item-text="fullName"
                          item-value="id"
                          return-object
                          v-model="application.shareRiskPartners"
                          multiple chips outlined dense
                          :rules="[
                          (v=>(v != null && v.length > 0) || 'Поле не должно быть пустым'),
                        ]"
                ></v-select>
              </v-col>
            </v-row>


          </div>
        </v-form>
      </v-container>


    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
          @click="save"
          style="width: 100%"
      >
        Сохранить
      </v-btn>
    </v-card-actions>


    <v-card-text>
      <v-container>
        <v-row>
          <v-col
              cols="12"
          >
            <p><a href="https://supt.nisse.ru/publicfiles/Бизнес-процессы по проектной деятельности 270421_v4_чист.docx">Описание процессов проектной деятельности.docx</a></p>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>


  </v-card>
</template>

<script>
import {deleteNullFields, normalizeNumber} from "@/modules/CommonUtils";
import {loadDataToObject} from "@/modules/CommonUtils";
import {riskAssessmentDict} from "@/modules/NSI";
import api from "@/modules/api";

export default {
  name: 'AddApplication',
  props: ['tender', 'editAppIndex'],
  data: () => ({
    application: {},
    isExternalOrg: false,
    riskAssessmentDict: riskAssessmentDict,
    apiLoaded: false
  }),
  methods: {
    loadData: loadDataToObject,
    normalizeNumber: normalizeNumber,
    async save() {
      if(!this.$refs.form.validate()) return;
      this.application.notification = {id: this.tender.id}
      this.application.appPrice = normalizeNumber(this.application.appPrice, 0, 1e13, 2)
      this.application = deleteNullFields(this.application, true)
      if(!this.application.offerExpert || this.application.offerExpert.isRolePartner)  delete this.application.curator
      if(this.isExternalOrg) delete this.application.ourOrg
      else delete this.application.differentOrgName
      if(this.application.partnersApproved) delete this.application.shareRiskPartners
      if(this.application.id == null) this.application.applicationStatus = 'NOTSTARTED'
      let req = this.application.id == null ? await api.postJson('/supmain/applications', this.application) :
          await api.putJson('/supmain/applications', this.application)
      console.log(req)
      if(req.ok) {
        this.$emit('update')
        this.$emit('close')
      }
    }
  },
  computed: {
    discount() {
      let price = normalizeNumber(this.application.appPrice, 0, 1e13, 2)
      return (100 - price / this.tender.maxPrice * 100).toFixed(0)
    },
  },
  async beforeMount() {
    await Promise.all([
      this.loadData('/supmain/legalentities?page=0&size=100&sort=id,asc&search=isOurOrg:true', 'ourOrgs', true),
      this.loadData('/supmain/experts?page=0&size=100&sort=id,asc&search=isRolePartner:true', 'partners', true),
      this.loadData('/supmain/experts?page=0&size=100&sort=id,asc&search=isRoleOffer:true', 'experts', true)
    ])

    this.ourOrgs = this.ourOrgs.map(e => {
      if (!e.shortName) e.shortName = e.fullName
      return e
    })

    const getFullName = function (items) {
      return items.map(e => {
        e.fullName = [e.surname, e.name, e.middleName].join(' ')
        if (e.fullName === '  ') e.fullName = 'Без имени'
        return e
      })
    }
    this.experts = getFullName(this.experts)
    this.partners = getFullName(this.partners)

    if(this.editAppIndex != null) this.application = JSON.parse(JSON.stringify(this.tender.applications[this.editAppIndex]))
    if(this.application.differentOrgName) this.isExternalOrg = true
    console.log('app', this.application)

    this.apiLoaded = true
  }
}
</script>

<style lang="scss" scoped>
.v-dialog a {
  text-decoration: underline;
}
</style>
