<template>
  <v-card v-if="apiLoaded">
    <v-card-title>
      <span class="text-h5">Редактирование заявки</span>
      <a class="close" @click="$emit('close')">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
              fill="#0033A0"/>
        </svg>
      </a>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-form ref="form">
          <div class="">
            <v-row>
              <v-col cols="12">
                <v-select
                    label="Ответственный"
                    :items="experts"
                    item-text="fullName"
                    item-value="id"
                    return-object
                    v-model="application.formalExpert"
                    outlined dense
                    :rules="[
                    (v=>(v != null) || 'Поле не должно быть пустым'),
                  ]"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-select
                    label="Статус заявки"
                    :items="applicationStatusDict"
                    item-text="title"
                    item-value="code"
                    v-model="application.applicationStatus"
                    outlined dense
                    :rules="[
                    (v=>(v != null) || 'Поле не должно быть пустым'),
                  ]"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-checkbox label="Обеспечение внесено" v-model="application.guaranteePaid"></v-checkbox>
              </v-col>
            </v-row>

            <v-row v-if="application.guaranteePaid">
              <v-col cols="12">
                <v-select
                    label="Статус заявки"
                    :items="guaranteeTypeDict"
                    item-text="title"
                    item-value="code"
                    v-model="application.guaranteeType"
                    outlined dense
                    :rules="[
                    (v=>(v != null) || 'Поле не должно быть пустым'),
                  ]"
                ></v-select>
              </v-col>
            </v-row>
          </div>
        </v-form>
      </v-container>


    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
          @click="save"
          style="width: 100%"
      >
        Сохранить
      </v-btn>
    </v-card-actions>


    <v-card-text>
      <v-container>
        <v-row>
          <v-col
              cols="12"
          >
            <p><a href="https://supt.nisse.ru/publicfiles/Бизнес-процессы по проектной деятельности 270421_v4_чист.docx">Описание процессов проектной деятельности.docx</a></p>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>


  </v-card>
</template>

<script>
import {deleteNullFields} from "@/modules/CommonUtils";
import {loadDataToObject} from "@/modules/CommonUtils";
import {applicationStatusDict, guaranteeTypeDict, riskAssessmentDict} from "@/modules/NSI";
import api from "@/modules/api";

export default {
  name: 'AddFormalApplicationDialog',
  props: ['tender', 'editAppIndex'],
  data: () => ({
    application: {},
    isExternalOrg: false,
    riskAssessmentDict,
    applicationStatusDict,
    guaranteeTypeDict,
    apiLoaded: false
  }),
  methods: {
    loadData: loadDataToObject,
    async save() {
      if(!this.$refs.form.validate()) return;
      this.application.notification = {id: this.tender.id}
      this.application = deleteNullFields(this.application, true)
      let req = this.application.id == null ? await api.postJson('/supmain/applications', this.application) :
          await api.putJson('/supmain/applications', this.application)
      console.log(req)
      if(req.ok) {
        this.$emit('update')
        this.$emit('close')
      }
    }
  },
  computed: {
  },
  async beforeMount() {
    await Promise.all([
      this.loadData('/supmain/experts?page=0&size=100&sort=id,asc&search=isRoleFormal:true', 'experts', true)
    ])

    const getFullName = function (items) {
      return items.map(e => {
        e.fullName = [e.surname, e.name, e.middleName].join(' ')
        if (e.fullName === '  ') e.fullName = 'Без имени'
        return e
      })
    }
    this.experts = getFullName(this.experts)
    this.application = JSON.parse(JSON.stringify(this.tender.applications[this.editAppIndex]))
    this.apiLoaded = true
  }
}
</script>

<style lang="scss" scoped>
.v-dialog a {
  text-decoration: underline;
}
</style>
